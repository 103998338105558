<template>
  <div class="main-container">
    <div>
      <div class="title flex-wrap flex-h-center">
        <div class="horn-title-style">职位详情</div>
        <div class="flex-con flex-wrap flex-h-center flex-between">
          <div class="menu-nav">
            入驻企业<a-icon type="right" />企业详情<a-icon
              type="right"
            />职位详情
          </div>
          <div class="back-btn" @click="$router.go(-1)">返回</div>
        </div>
      </div>
      <div class="content">
        <div class="roughly">
          <div class="position">{{ recruitData.position }}</div>
          <div class="wages">{{ recruitData.money }}</div>
          <div class="other">
            <span>{{ recruitData.experience }}</span>
            <a-divider type="vertical" />
            <span>{{ recruitData.education }}</span>
            <a-divider type="vertical" />
            <span>{{ recruitData.nature }}</span>
            <a-divider type="vertical" />
            <span>招{{ recruitData.planCount }}人</span>
          </div>
        </div>

        <div class="specific">
          <div v-html="recruitData.discription"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dictQueryAllDictItems } from '@/api/api'
export default {
  data() {
    return {
      recruitData: {},
      dictData: {},
      welfareList: [],
    }
  },

  created() {
    if (this.$route.params.data) {
      this.recruitData = JSON.parse(
        decodeURIComponent(window.atob(this.$route.params.data))
      )
      console.log(this.recruitData)
    }
  },
  methods: {},
}
</script>
<style lang="less" scoped>
.main-container {
  background: #f4f4f4;
  padding: 50px 0;

  > div {
    width: 1200px;
    min-height: 600px;
    margin: 0 auto;
    background: #fff;

    .title {
      border-bottom: 1px solid #e5e5e5;
      background: #fff;

      .menu-nav {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        margin-left: 17px;
      }

      .back-btn {
        width: 109px;
        height: 39px;
        line-height: 39px;
        background: #1285d6;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-right: 34px;
        cursor: pointer;
      }
    }

    .content {
      padding: 35px;
      .roughly {
        .position {
          font-size: 28px;
          font-weight: bold;
          color: #333333;
        }

        .wages {
          font-size: 30px;
          color: #ff9a15;
          margin: 20px 0 10px 0;
        }

        .other {
          font-size: 16px;
          color: #333333;

          /deep/ .ant-divider,
          .ant-divider-vertical {
            width: 1px;
            height: 20px;
            background: #c3c3c3;
            margin: 0 15px;
          }
        }
      }
    }

    .specific {
      padding: 50px 0;
      font-size: 14px;
      color: #333;
    }
  }
}

.horn-title-style {
  width: 160px;
  height: 55px;
  line-height: 55px;
  background-color: #1285d6;
  position: relative;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: rgb(18, 133, 214);
    top: 100%;
    left: 50%;
    position: absolute;
    margin-left: -12px;
  }
}
</style>
