<template>
  <div>
    <div class="notice">
      <div class="newest">
        <div class="flex-wrap flex-h-center">
          <div class="newest-title">最新公告</div>
          <div class="newest-content flex-con">
            <swiper
              :options="swiperOptionNotice"
              ref="noticeSwiper"
              v-if="noticeList.length > 0 && isKeep"
            >
              <swiper-slide v-for="(item, index) in noticeList" :key="index">
                <div @click="openArticleDetails(item, 'notice')">
                  {{ item.title }}
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>

      <div class="overview">
        <ul class="flex-wrap flex-h-center">
          <li class="flex-con">
            <div>
              <span class="number">{{ statisticsData.enterpriseCount }}</span
              ><span class="unit">家</span>
            </div>
            <div class="name">入驻企业</div>
          </li>
          <li class="flex-con">
            <div>
              <span class="number">{{ statisticsData.agencyCount }}</span
              ><span class="unit">家</span>
            </div>
            <div class="name">服务机构</div>
          </li>
          <li class="flex-con">
            <div>
              <span class="number">{{ statisticsData.activityCount }}</span
              ><span class="unit">次</span>
            </div>
            <div class="name">办理活动</div>
          </li>
          <li class="flex-con">
            <div>
              <span class="number">{{ statisticsData.processCount }}</span
              ><span class="unit">次</span>
            </div>
            <div class="name">办理业务</div>
          </li>
          <li class="flex-con">
            <div>
              <span class="number">{{ statisticsData.recruitlCount }}</span
              ><span class="unit">条</span>
            </div>
            <div class="name">招聘条数</div>
          </li>
        </ul>
      </div>

      <div class="old flex-wrap">
        <div class="old-left">
          <swiper
            :options="swiperOptionHotspot"
            ref="hotspotSwiper"
            v-if="indexHotInfoList.length > 0 && isKeep"
          >
            <swiper-slide
              v-for="(item, index) in indexHotInfoList"
              :key="index"
            >
              <div @click="openArticleDetails(item, 'info')">
                <div>
                  <img
                    :src="item.indexPicture"
                    alt=""
                    width="580"
                    height="442"
                  />
                </div>
                <div class="flex-wrap flex-h-center hotspot-title">
                  <div class="btn">{{ getDate(item.createTime) }}</div>
                  <div class="old-content">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-btn button-prev"></div>
          <div class="swiper-btn button-next"></div>
        </div>
        <div class="old-right flex-con">
          <div class="tab-nav flex-wrap">
            <ul class="flex-wrap old-nav">
              <li
                :class="{ active: tabIndex === index }"
                @mouseenter="mousemoveTab(index)"
                v-for="(item, index) in informationList.slice(0, 3)"
                :key="index"
              >
                {{ item.informationType.typeName }}
              </li>
            </ul>
          </div>

          <div class="tab-content">
            <ul
              class="old-content slideInRight"
              v-show="tabIndex == index"
              v-for="(item, index) in informationList.slice(0, 3)"
              :key="index"
            >
              <li
                v-if="item.informations.length > 0"
                class="flex-wrap"
                @click="openArticleDetails(item.informations[0], 'info')"
              >
                <img
                  :src="item.informations[0].indexPicture"
                  alt=""
                  width="134"
                  height="116"
                />
                <div class="flex-wrap flex-vertical flex-between">
                  <div class="title">
                    {{ item.informations[0].title }}
                  </div>
                  <div class="content">
                    {{
                      getText(item.informations[0].content).substring(0, 70)
                    }}...
                  </div>
                  <div class="date">
                    {{ item.informations[0].createTime.split(' ')[0] }}
                  </div>
                </div>
              </li>
              <li v-else>暂无数据</li>
              <li
                class="flex-wrap flex-h-center flex-between"
                v-for="(data, listIndex) in item.informations.slice(1)"
                :key="listIndex"
                @click="openArticleDetails(data, 'info')"
              >
                <span>{{ data.title }}</span>
                <span>{{ data.createTime.split(' ')[0] }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="record">
      <div>
        <div class="bg-title">
          <div class="cn-name">平台数据</div>
          <div class="en-name">Platform Data</div>
          <div class="h-line"></div>
        </div>
        <div class="data-nav">
          <ul class="flex-wrap flex-between first-ul">
            <li class="flex-wrap flex-h-center">
              <img src="@/assets/data_1.png" alt="" width="56" height="48" />
              <div>
                <div class="num">302（家）</div>
                <div class="name">入住企业</div>
              </div>
            </li>
            <li class="flex-wrap flex-h-center">
              <img src="@/assets/data_2.png" alt="" width="56" height="47" />
              <div>
                <div class="num">302（家）</div>
                <div class="name">投资单位</div>
              </div>
            </li>
            <li class="flex-wrap flex-h-center">
              <img src="@/assets/data_3.png" alt="" width="50" height="50" />
              <div>
                <div class="num">302（万）</div>
                <div class="name">人才数量</div>
              </div>
            </li>
          </ul>
          <ul class="flex-wrap flex-between two-ul">
            <li class="flex-wrap flex-h-center">
              <img src="@/assets/data_4.png" alt="" width="54" height="54" />
              <div>
                <div class="num">302（家）</div>
                <div class="name">行业分布</div>
              </div>
            </li>
            <li class="flex-wrap flex-h-center">
              <img src="@/assets/data_5.png" alt="" width="58" height="48" />
              <div>
                <div class="num">60（亩）</div>
                <div class="name">占地面积</div>
              </div>
            </li>
            <li class="flex-wrap flex-h-center">
              <img src="@/assets/data_6.png" alt="" width="51" height="53" />
              <div>
                <div class="num">26（人）</div>
                <div class="name">本周新增企业数量</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> -->

    <div class="business">
      <div>
        <div class="bg-title">
          <div class="cn-name">平台业务</div>
          <div class="en-name">Platform Business</div>
          <div class="h-line"></div>
        </div>

        <div class="business-nav">
          <ul class="flex-wrap flex-h-center flex-wrap-wrap">
            <li class="my-element" @click="openPage(0)">
              <span>新闻资讯</span>
            </li>
            <li class="my-element" @click="openPage(1)">
              <span>物业服务</span>
            </li>
            <li class="my-element" @click="openPage(2)">
              <span>企业服务</span>
            </li>
            <li class="my-element" @click="openPage(3)">
              <span>招商快讯</span>
            </li>
            <li class="my-element" @click="openPage(4)">
              <span>平台活动</span>
            </li>
            <li class="my-element" @click="openPage(5)">
              <span>入孵申请</span>
            </li>
            <li class="my-element" @click="openPage(6)">
              <span>平台招聘</span>
            </li>
            <li class="my-element" @click="openPage(7)">
              <span>平台动态</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 平台介绍 -->
    <div class="introduce">
      <div>
        <div class="bg-title">
          <div class="cn-name">平台介绍</div>
          <div class="en-name">INTRODUCTION</div>
          <div class="h-line"></div>
        </div>

        <div class="introduce-content flex-wrap">
          <div class="content-left flex-wrap flex-between">
            <div class="title">平台介绍</div>
            <div class="content">
              {{ setParkBasicInfo(sysParameterList, 'park_introduce') }}
            </div>
          </div>
          <div class="content-right flex-con">
            <div class="introduce-video">
              <video
                :src="setParkBasicInfo(sysParameterList, 'park_video')"
                :poster="
                  setParkBasicInfo(sysParameterList, 'park_video') +
                  '?x-oss-process=video/snapshot,t_2000,m_fast'
                "
                style="width: 100%; height: 100%"
                id="videoBox"
                ref="videoPlay"
                :controls="controls"
              ></video>
              <div class="video-img" v-show="plays" @click="videoImg">
                <img src="@/assets/image/play.png" />
              </div>
            </div>
            <div class="introduce-nav">
              <ul class="flex-wrap flex-h-center">
                <li class="flex-con" @click="openAbout(0)">
                  <div>平台交通</div>
                </li>
                <li class="flex-con" @click="openAbout(1)">
                  <div>联系方式</div>
                </li>
                <li class="flex-con" @click="openAbout(2)">
                  <div>平台荣誉</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 入驻企业 -->
    <div class="settled">
      <div>
        <div class="bg-title">
          <div class="cn-name">入驻企业</div>
          <div class="en-name">Settled Enterprise</div>
          <div class="h-line"></div>
        </div>
        <div class="swiper-box">
          <swiper
            :options="swiperOptionEnterprise"
            ref="enterpriseSwiper"
            v-if="yqmsEnterpriseList.length > 0 && isKeep"
          >
            <swiper-slide
              v-for="(item, index) in yqmsEnterpriseList"
              :key="index"
            >
              <div
                class="swiper-item flex-wrap flex-vertical flex-h-center"
                @click="openBusinessDetails(item)"
              >
                <div class="top-decorate"></div>
                <div class="image">
                  <img
                    :src="item.enterpriseLogo"
                    alt=""
                    width="181"
                    height="181"
                    v-show="item.enterpriseLogo"
                  />
                  <img
                    src="@/assets/zwtp.png"
                    alt=""
                    width="181"
                    height="181"
                    v-show="!item.enterpriseLogo"
                  />
                </div>
                <div class="name t-hide">{{ item.enterpriseName }}</div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div
        class="swiper-btn but-prev"
        v-if="yqmsEnterpriseList.length >= 5"
      ></div>
      <div
        class="swiper-btn but-next"
        v-if="yqmsEnterpriseList.length >= 5"
      ></div>
    </div>

    <!-- 合作机构 -->
    <div class="cooperating">
      <div>
        <div class="bg-title">
          <div class="cn-name">合作机构</div>
          <div class="en-name">Cooperating Agency</div>
          <div class="h-line"></div>
        </div>
        <div class="agency-list">
          <ul class="flex-wrap flex-wrap-wrap flex-between">
            <li>
              <img src="@/assets/kj.png" alt="" width="320" />
            </li>

            <li class="flex-wrap flex-center">
              <img src="@/assets/sc.png" alt="" width="320" />
            </li>

            <li class="flex-wrap flex-center">
              <img src="@/assets/ld.png" alt="" width="320" />
            </li>
            <li>
              <img src="@/assets/gs.png" alt="" width="320" />
            </li>

            <li>
              <img src="@/assets/js.png" alt="" width="320" />
            </li>

            <li>
              <img src="@/assets/zs.png" alt="" width="320" />
            </li>

            <li>
              <img src="@/assets/ms.png" alt="" width="320" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { publicInfoIndex, statisticalPcCount } from '@/api/api'
import { setParkBasicInfo } from '@/api/common'

export default {
  name: 'Home',
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOptionNotice: {
        direction: 'vertical',
        observer: true,
        observeParents: true,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        allowTouchMove: false,
      },
      swiperOptionHotspot: {
        observer: true,
        observeParents: true,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        allowTouchMove: false,
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev',
        },
      },
      swiperOptionEnterprise: {
        observer: true,
        observeParents: true,
        loop: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        allowTouchMove: false,
        slidesPerView: 5,
        spaceBetween: 5,
        navigation: {
          nextEl: '.but-next',
          prevEl: '.but-prev',
        },
      },
      plays: true,
      controls: false,
      tabIndex: 0,
      noticeList: [],
      indexHotInfoList: [],
      informationList: [],
      sysParameterList: [],
      yqmsEnterpriseList: [],
      parkInfo: {
        introduceText: '',
        introduceVideo: '',
      },
      isKeep: false,
      statisticsData: {},
    }
  },
  activated() {
    this.isKeep = true
    this.getInfo()
    this.statisticsInfo()
  },
  deactivated() {
    this.isKeep = false
  },
  created() {
    this.getInfo()
    this.statisticsInfo()
  },
  methods: {
    setParkBasicInfo,
    async getInfo() {
      try {
        const res = await publicInfoIndex()
        if (res.code == 200) {
          this.noticeList = res.result.noticeList
          this.indexHotInfoList = res.result.indexHotInfoList
          this.informationList = res.result.yqmsInformationByTypeLists
          console.log(this.informationList)
          this.sysParameterList = res.result.sysParameterList
          this.yqmsEnterpriseList = res.result.YqmsEnterpriseList
          console.log(this.yqmsEnterpriseList)
          if (this.yqmsEnterpriseList.length >= 5) {
            this.swiperOptionEnterprise.loop = true
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    mousemoveTab(index) {
      this.tabIndex = index
    },
    videoImg() {
      this.plays = false
      this.controls = true
      this.$refs['videoPlay'].play()
    },
    openArticleDetails(data, type) {
      this.$router.push({
        name: 'ArticleDetails',
        params: {
          id: data.id,
          type: type,
        },
      })
    },
    openBusinessDetails(data) {
      this.$router.push({
        name: 'BusinessDetails',
        params: {
          data: window.btoa(encodeURIComponent(JSON.stringify(data))),
        },
      })
    },
    getText(str) {
      return str
        .replace(/<[^<>]+>/g, '')
        .replace(/&nbsp;/gi, '')
        .replace(/&ldquo;/gi, '')
        .replace(/&rdquo;/gi, '')
        .replace(/&rdquo;/gi, '')
        .replace(/&mdash;/gi, '')
        .replace(/&middot;/gi, '')
        .replace(/\s/g, '')
    },
    getDate(str) {
      const date = str.split(' ')[0]
      const month = date.split('-')[1]
      const day = date.split('-')[2]
      return month + '/' + day
    },
    openPage(index) {
      if (index === 0 || index === 3 || index === 4 || index === 7) {
        this.$router.push({
          name: 'PolicyIndex',
          params: {
            index: index,
          },
        })
      } else if (index === 1 || index === 2 || index === 5) {
        this.$router.push({
          name: 'ServiceIndex',
          params: {
            index: index,
          },
        })
      } else if (index === 6) {
        this.$router.push({
          name: 'Recruit',
        })
      }
    },
    openAbout(index) {
      this.$router.push({
        name: 'About',
        params: {
          index: index,
        },
      })
    },
    async statisticsInfo() {
      try {
        const res = await statisticalPcCount()
        if (res.code == 200) {
          this.statisticsData = res.result
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err.message)
      }
    },
  },
}
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.top-bg {
  position: relative;
}

.notice {
  margin: 0 auto;
  position: relative;
  top: -3px;

  .newest {
    height: 50px;
    margin: 0 auto;
    background: #f1f1f1;

    > div {
      width: 1200px;
      margin: 0 auto;

      .newest-title {
        width: 109px;
        line-height: 50px;
        background: #1285d6;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
      }

      .newest-content {
        font-size: 14px;
        font-weight: 400;
        color: #1285d6;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        cursor: pointer;

        .swiper-container {
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }

  .old {
    width: 1200px;
    margin: 0 auto;
    padding: 55px 0 100px 0;
    .old-left {
      width: 580px;
      position: relative;
      cursor: pointer;

      .hotspot-title {
        margin-top: 15px;
        .btn {
          width: 105px;
          height: 56px;
          line-height: 56px;
          background: #1285d6;
          font-size: 20px;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
        }

        .old-content {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          margin-left: 10px;
        }
      }

      .swiper-btn {
        width: 35px;
        height: 58px;
        z-index: 10;
        position: absolute;
        top: 192px;
        cursor: pointer;
      }

      .button-prev {
        background-image: url(~@/assets/prev.png);
        left: 0;
      }

      .button-next {
        background-image: url(~@/assets/next.png);
        right: 0;
      }
    }

    .old-right {
      margin-left: 40px;
      .tab-nav {
        .old-nav {
          // width: 470px;
          border: 2px solid #1285d6;
          border-radius: 5px;

          li {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            border-right: 2px solid #1285d6;
            padding: 16px 45px;
            cursor: pointer;

            &:last-child {
              border: none;
            }

            &.active {
              background: #1285d6;
              color: #ffffff;
            }
          }
        }
      }

      .tab-content {
        overflow: hidden;
        .old-content {
          li {
            border-bottom: 1px dashed #e2e2e2;
            padding: 25px 0;
            cursor: pointer;
            span {
              &:first-child {
                font-size: 14px;
                color: #333333;
              }

              &:last-child {
                font-size: 12px;
                color: #999999;
              }
            }

            &:first-child {
              padding: 32px 0 14px 0;
              img {
                margin-right: 10px;
              }
              .title {
                color: #333333;
              }

              .content {
                font-size: 12px;
                color: #666666;
                margin-left: 3px;
              }

              .date {
                font-size: 12px;
                color: #999999;
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}

.record {
  background: #f1f1f1;
  > div {
    height: 100%;
    width: 1200px;
    margin: 0 auto;

    .data-nav {
      padding: 69px 0 38px 0;

      .first-ul {
        li:nth-child(1) {
          .num {
            color: #78c446;
          }
        }

        li:nth-child(2) {
          .num {
            color: #f44c4c;
          }
        }

        li:nth-child(3) {
          .num {
            color: #5ed8a9;
          }
        }
      }

      .two-ul {
        li:nth-child(1) {
          .num {
            color: #f99c4e;
          }
        }

        li:nth-child(2) {
          .num {
            color: #6c6fbf;
          }
        }

        li:nth-child(3) {
          .num {
            color: #4fc5ea;
          }
        }
      }

      li {
        width: 311px;
        height: 110px;
        background: #fff;
        margin-bottom: 62px;
        padding-left: 46px;

        img {
          margin-right: 53px;
        }

        .num {
          font-size: 20px;
          font-weight: bold;
        }

        .name {
          font-size: 16px;
          color: #808080;
        }
      }
    }
  }
}

.business {
  background: #f1f1f1;
  padding-bottom: 80px;

  > div {
    height: 100%;
    width: 1200px;
    margin: 0 auto;

    .business-nav {
      ul {
        margin-top: 55px;
        li {
          height: 208px;
          margin-bottom: 12px;
          position: relative;

          span {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            position: absolute;
            left: 20px;
            bottom: 17px;
          }

          &:nth-child(1) {
            width: 196px;
            background: #3387ea;
            background-image: url(~@/assets/icon1.png);
            background-repeat: no-repeat;
            background-position: center;
          }

          &:nth-child(2) {
            width: 196px;
            background: #f9be3e;
            margin-left: 12px;
            background-image: url(~@/assets/icon2.png);
            background-repeat: no-repeat;
            background-position: center;
          }

          &:nth-child(3) {
            width: 446px;
            background: #d4583e;
            margin-left: 12px;
            background-image: url(~@/assets/icon3.png);
            background-repeat: no-repeat;
            background-position: center;
          }

          &:nth-child(4) {
            width: 326px;
            background: #33af95;
            margin-left: 12px;
            background-image: url(~@/assets/icon4.png);
            background-repeat: no-repeat;
            background-position: center;
          }

          &:nth-child(5) {
            width: 404px;
            background: #86a73f;
            background-image: url(~@/assets/icon5.png);
            background-repeat: no-repeat;
            background-position: center;
          }

          &:nth-child(6) {
            width: 196px;
            background: #59b1e3;
            margin-left: 12px;
            background-image: url(~@/assets/icon6.png);
            background-repeat: no-repeat;
            background-position: center;
          }

          &:nth-child(7) {
            width: 196px;
            background: #777777;
            margin-left: 12px;
            background-image: url(~@/assets/icon7.png);
            background-repeat: no-repeat;
            background-position: center;
          }

          &:nth-child(8) {
            width: 368px;
            background: #f99c4e;
            margin-left: 12px;
            background-image: url(~@/assets/icon8.png);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
}

.introduce {
  padding-bottom: 100px;
  background: #fff;
  > div:nth-child(1) {
    width: 1200px;
    margin: 0 auto;

    .introduce-content {
      margin-top: 55px;
      .content-left {
        padding: 41px 40px 32px 30px;
        width: 275px;
        background: #2b2b2b;

        .title {
          width: 43px;
          font-size: 30px;
          font-weight: normal;
          color: #fcfcfc;
          line-height: 36px;
          text-align: center;
          margin-top: -6px;
          margin-right: 4px;
          background-image: url(~@/assets/image/b_icon.png);
          background-repeat: no-repeat;
          background-position: 0 70px;
        }

        .content {
          height: 639px;
          writing-mode: vertical-rl; /*从左向右 从右向左是 writing-mode: vertical-rl;*/
          writing-mode: tb-lr; /*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
          font-weight: 400;
          color: #fcfcfc;
          line-height: 36px;
        }
      }

      .content-right {
        background: #f4f4f4;

        .introduce-video {
          height: 449px;
          position: relative;
          #videoBox {
            object-fit: fill;
            display: inline-block;
            vertical-align: baseline;
          }
          .video-img {
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -57px;
            margin-left: -57px;
          }
          //解决视频有黑色的线框
          video:focus {
            outline: -webkit-focus-ring-color auto 0px;
          }
        }

        .introduce-nav {
          padding-top: 43px;
          ul {
            li {
              width: 138px;
              padding-top: 168px;
              background-repeat: no-repeat;
              background-position: center top;
              cursor: pointer;

              &:nth-child(1) {
                background-image: url(~@/assets/introduce1.png);
              }

              &:nth-child(2) {
                background-image: url(~@/assets/introduce2.png);
              }

              &:nth-child(3) {
                background-image: url(~@/assets/introduce3.png);
              }

              div {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

.settled {
  background: #f1f1f1;
  padding-bottom: 88px;
  position: relative;
  > div:nth-child(1) {
    width: 1200px;
    margin: 0 auto;

    .swiper-box {
      margin-top: 55px;

      .swiper-slide {
        background: #ffffff;
        border: 1px solid #d0d0d0;
      }

      .swiper-item {
        padding: 0 20px;
        cursor: pointer;
        .top-decorate {
          width: 30px;
          height: 6px;
          background: #d0d0d0;
        }

        .image {
          margin: 12px 0 15px 0;
        }

        .name {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          padding-bottom: 5px;
          text-align: center;
        }
      }
    }
  }

  .swiper-btn {
    position: absolute;
    top: 300px;
    background: rgba(0, 0, 0, 0.05);
    opacity: 0;
    width: 0;
    height: 58px;
    z-index: 10;
    cursor: pointer;
    transition-property: opacity, width;
    transition-duration: 0.5s;
  }

  .but-prev {
    background-image: url(~@/assets/prev.png);
    left: 0;
  }

  .but-next {
    background-image: url(~@/assets/next.png);
    right: 0;
  }

  &:hover {
    .swiper-btn {
      opacity: 1;
      width: 35px;
    }
  }
}

.cooperating {
  background: #fff;
  > div:nth-child(1) {
    width: 1200px;
    margin: 0 auto;

    .agency-list {
      padding: 100px 0;
      ul {
        li {
          width: 360px;
          height: 148px;
          padding: 10px 20px;
          box-shadow: 0px 2px 49px 0px rgba(0, 0, 0, 0.27);
          margin-bottom: 50px;
        }
      }
    }
  }
}

.bg-title {
  width: 489px;
  height: 149px;
  background: #1285d6;
  color: #fff;
  padding: 22px 0 0 42px;

  .cn-name {
    font-size: 32px;
  }

  .en-name {
    font-size: 26px;
  }

  .h-line {
    width: 253px;
    height: 2px;
    background: #ffffff;
    margin-top: 10px;
  }
}

.overview {
  width: 1200px;
  margin: 50px auto;
  background: #ffffff;
  box-shadow: 0px 2px 49px 0px rgba(0, 0, 0, 0.27);
  ul {
    padding: 27px 0 24px 0;
    margin: 0;
    li {
      color: #1285d6;
      border-right: 2px solid #eee;
      text-align: center;
      padding: 4px 0 24px 0;
      .number {
        font-size: 70px;
        font-weight: bold;
      }
      .unit {
        font-size: 26px;
        font-weight: 400;
      }
      .name {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  header {
    .header-left {
      padding-left: 20px;
    }

    .header-right {
      padding: 17px 20px 17px 0;
    }
  }
}

.my-element {
  cursor: pointer;
  transition: transform 0.3s linear;

  &:hover {
    transform: scale(1.03, 1.03);
  }
}

.slideInRight {
  position: relative;
  animation: forward 2s;
}

@keyframes forward {
  0% {
    right: -580px;
  }

  100% {
    right: 0;
  }
}
</style>
